import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from '@/i18n'

import '@/assets/css/index.scss'

//获取浏览器语言标识
const navLang = navigator.language || navigator.userLanguage
let isCN = navLang.includes('zh') || navLang.includes('CN')
const app = createApp(App)

app.config.globalProperties.$globalData = {
  isCN,
}

app.use(store).use(router).use(i18n).mount('#app')
