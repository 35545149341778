import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1f5c74e4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "head-right h100 flex fy-c"
};
const _hoisted_2 = ["href"];
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ArrowDown } from '@element-plus/icons-vue';
export default {
  __name: 'HeadShopLink',
  setup(__props) {
    const {
      locale
    } = useI18n();
    const store = useStore();
    const obj = {
      CN: 'https://nitetronic.tmall.com',
      EN: 'https://nitetronic.com/discount/NTZ6260OFF?redirect=%2Fproducts%2Fnitetronic-z6-smart-anti-snore-pillow',
      JA: '/'
    };
    const globalData = inject('globalData');
    const lang = computed(() => store.state.bk.global.langs);
    const shop = computed(() => {
      let type = store.state.bk.global.langs;
      if (globalData.isCN) return obj.CN;else return obj.EN;
    });
    const langsToggle = str => {
      locale.value = str.toLowerCase();
      store.commit('bk/global/langsChange', str);
    };
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", {
        class: "shop-link block",
        href: shop.value
      }, [_createVNode(_component_el_image, {
        class: "bag",
        src: "https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/icon-bag.png",
        fit: "contain"
      })], 8, _hoisted_2)]);
    };
  }
};