import { createI18n } from 'vue-i18n'
import en from './en'
import cn from './cn'
import ja from './ja'

const messages = {
  en,
  cn,
  ja,
}
//获取浏览器语言标识
const navLang = navigator.language || navigator.userLanguage
let isCN = navLang.includes('zh') || navLang.includes('CN')
console.log(isCN)

const i18n = new createI18n({
  legacy: false,
  locale: isCN ? 'cn' : 'en',
  fallbackLocale: 'cn',
  globalInjection: true,
  messages,
})

export default i18n
