import i18n from '@/i18n'
const t = i18n.global.t

const footerList = [
  {
    id: 0,
    label: 'home.footer.brand01.name',
    arr: [
      {
        label: 'home.footer.brand01.label01',
        path: '/nitetronic',
      },
      {
        label: 'home.footer.brand01.label02',
        path: '/backrobo',
      },
    ],
  },
  {
    id: 1,
    label: 'home.footer.brand02.name',
    arr: [
      {
        label: 'home.footer.brand02.label01',
        path: '',
      },
      {
        label: 'home.footer.brand02.label02',
        path: '',
      },
      {
        label: 'home.footer.brand02.label03',
        path: '',
      },
    ],
  },
  {
    id: 2,
    label: 'home.footer.brand03.name',
    arr: [
      {
        label: 'home.footer.brand03.label01',
        path: '',
      },
      {
        label: 'home.footer.brand03.label02',
        path: '',
      },
      {
        label: 'home.footer.brand03.label03',
        path: '',
      },
    ],
  },
  {
    id: 3,
    label: 'home.footer.brand04.name',
    arr: [
      {
        label: 'home.footer.brand04.label01',
        path: '/us',
      },
      {
        label: 'home.footer.brand04.label02',
        path: '/technology',
      },
      {
        label: 'home.footer.brand04.label03',
        path: '/innovation',
      },
      {
        label: 'home.footer.brand04.label04',
        path: '/concept',
      },
    ],
  },
  {
    id: 4,
    label: 'home.footer.brand05.name',
    arr: [
      {
        label: 'home.footer.brand05.label01',
        path: '/news',
      },
    ],
  },
]

const enFooterList = [
  {
    id: 0,
    label: 'home.footer.brand01.name',
    arr: [
      {
        label: 'home.footer.brand01.label01',
        path: '/nitetronic',
      },
      {
        label: 'home.footer.brand01.label02',
        path: '/backrobo',
      },
    ],
  },
  {
    id: 1,
    label: 'home.footer.brand02.name',
    arr: [
      {
        label: 'home.footer.brand02.label01',
        path: 'https://www.nitetronic.com',
      },
      {
        label: 'home.footer.brand02.label02',
        path: 'https://www.backrobo.com',
      },
      {
        label: 'home.footer.brand02.label03',
        path: '',
      },
    ],
  },
  {
    id: 3,
    label: 'home.footer.brand04.name',
    arr: [
      {
        label: 'home.footer.brand04.label01',
        path: '/us/about',
      },
      {
        label: 'home.footer.brand04.label02',
        path: '/us/technology',
      },
      {
        label: 'home.footer.brand04.label03',
        path: '/us/innovation',
      },
      {
        label: 'home.footer.brand04.label04',
        path: '/concept',
      },
    ],
  },
  {
    id: 4,
    label: 'home.footer.brand05.name',
    arr: [
      {
        label: 'home.footer.brand05.label01',
        path: '/us/news',
      },
    ],
  },
  {
    id: 5,
    label: 'home.footer.brand06.name',
    arr: [
      {
        label: 'home.footer.brand06.label01',
        path: '/help',
      },
    ],
  },
]

const enIconsLink = [
  {
    type: 'img',
    icon: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/facebook-fill.png',
    url: 'https://www.facebook.com/nitetronicofficial',
  },
  {
    type: 'img',
    icon: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/instagram-fill.png',
    url: 'https://www.instagram.com/nitetronic_smart_pillow/',
  },
  {
    type: 'img',
    icon: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/x.jpg',
    url: 'https://www.instagram.com/nitetronic_smart_pillow/',
  },
  {
    type: 'img',
    icon: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/Youtube-fill.png',
    url: 'https://www.youtube.com/@nitetronic',
  },
  {
    type: 'img',
    icon: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/tiktok.png',
    url: 'https://www.tiktok.com/@nitetronic',
  },
]
const iconsLink = [
  {
    type: 'img',
    icon: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/icon-wx.png',
    txt: '微信扫一扫',
    img_url: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/icon-qr-bluepeace.jpg',
  },
  {
    type: 'img',
    icon: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/icon-zh.png',
    txt: '微信扫一扫',
    img_url: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/icon-qr-bluepeace.jpg',
  },
  {
    type: 'img',
    icon: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/icon-dy.png',
    txt: '微信扫一扫',
    img_url: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/icon-qr-bluepeace.jpg',
  },
  {
    type: 'img',
    icon: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/icon-xhs.png',
    txt: '微信扫一扫',
    img_url: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/icon-qr-backrobo.jpg',
  },
]

export { footerList, enFooterList, iconsLink, enIconsLink }
