import i18n from '@/i18n'
const t = i18n.global.t

const menuList = [
  // {
  //   id: 0,
  //   path: '/',
  //   label: 'home.menu.home'
  // },
  {
    id: 0,
    path: '/nitetronic',
    label: 'home.menu.nitetronic',
  },
  {
    id: 1,
    path: '/backrobo',
    label: 'home.menu.backrobo',
  },
  {
    id: 2,
    path: '/concept',
    label: 'home.menu.concept',
  },
  {
    id: 3,
    path: '/help',
    label: 'home.menu.support',
  },
  {
    id: 4,
    path: '/us',
    label: 'home.menu.aboutus',
  },
]

const aboutusMenu = [
  {
    id: 0,
    path: '/us/about',
    label: 'home.aboutusMenu.company',
  },
  {
    id: 1,
    path: '/us/technology',
    label: 'home.footer.brand04.label02',
  },
  {
    id: 2,
    path: '/us/innovation',
    label: 'home.footer.brand04.label03',
  },
  {
    id: 3,
    path: '/us/news',
    label: 'home.aboutusMenu.news',
  },
]

export { menuList, aboutusMenu }
