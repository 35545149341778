import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-54fce9c7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "sub"
};
const _hoisted_3 = {
  class: "relative flex fx-c fy-c"
};
const _hoisted_4 = {
  class: "outter flex fx-c fy-c"
};
const _hoisted_5 = {
  class: "middle flex fx-c fy-c"
};
const _hoisted_6 = {
  class: "inner flex fx-c fy-c"
};
const _hoisted_7 = ["src"];
import { useStore } from 'vuex';
import { computed, reactive } from '@vue/runtime-core';
import { VideoPlay } from '@element-plus/icons-vue';
export default {
  __name: 'VideoView',
  props: {
    videos: Object
  },
  setup(__props) {
    const store = useStore();
    let isMobile = computed(() => store.state.bk.global.isMobile);
    const data = reactive({
      modalShow: false
    });
    const openVideo = () => {
      data.modalShow = !data.modalShow;
    };
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_PopCover = _resolveComponent("PopCover");
      return _openBlock(), _createElementBlock("section", null, [_createElementVNode("div", {
        class: _normalizeClass(['videos w100 mt-30 flex fy-c fx-c', {
          h5: _unref(isMobile)
        }])
      }, [_createElementVNode("div", {
        class: "wrapper flex fy-c fx-c fd-c",
        onClick: openVideo
      }, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('home.video.title')), 1), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('home.video.sub')), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_icon, {
        class: "aboslute",
        color: "#fff",
        size: _unref(isMobile) ? 18 : 36
      }, {
        default: _withCtx(() => [_createVNode(_unref(VideoPlay))]),
        _: 1
      }, 8, ["size"])])])])])]), _createElementVNode("img", {
        class: "w100",
        src: __props.videos.img_url,
        style: {
          "objectfit": "cover"
        }
      }, null, 8, _hoisted_7), _createVNode(_component_PopCover, {
        modalShow: _unref(data).modalShow,
        video: __props.videos,
        onClose: openVideo
      }, null, 8, ["modalShow", "video"])], 2)]);
    };
  }
};