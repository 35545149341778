import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/backrobo',
    name: 'backrobo',
    component: () => import(/* webpackChunkName: "backrobo" */ '../views/backrobo/BackroboView.vue'),
  },
  {
    path: '/nitetronic',
    name: 'nitetronic',
    component: () => import(/* webpackChunkName: "nitetronic" */ '../views/nitetronic/NitetronicView.vue'),
  },
  {
    path: '/concept',
    name: 'concept',
    component: () => import(/* webpackChunkName: "concept" */ '../views/concept/ConceptView.vue'),
  },

  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "support" */ '../views/help/Help.vue'),
  },
  {
    path: '/us',
    name: 'us',
    component: () => import(/* webpackChunkName: "support" */ '../views/us/UsView.vue'),
    redirect: '/us/about',
    children: [
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "productions" */ '../views/about/about.vue'),
      },
      {
        path: 'productions',
        name: 'productions',
        component: () => import(/* webpackChunkName: "productions" */ '../views/productions/ProductionsView.vue'),
      },
      {
        path: 'technology',
        name: 'technology',
        component: () => import(/* webpackChunkName: "technology" */ '../views/technology/TechnologyView.vue'),
      },
      {
        path: 'innovation',
        name: 'innovation',
        component: () => import(/* webpackChunkName: "innovation" */ '../views/innovation/InnovationView.vue'),
      },
      {
        path: 'news',
        name: 'news',
        component: () => import(/* webpackChunkName: "news" */ '../views/news/NewsView.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

export default router
