import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-131a2789"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "hidden"
};
const _hoisted_2 = {
  class: "head tc relative"
};
const _hoisted_3 = {
  class: "en"
};
const _hoisted_4 = {
  class: "title tc"
};
export default {
  __name: 'PCContentWrapperView',
  props: {
    titles: Object
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("section", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(props.titles.en), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(props.titles.title), 1)]), _renderSlot(_ctx.$slots, "default")])]);
    };
  }
};