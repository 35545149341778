import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-af097576"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "content flex fx-c fy-c"
};
const _hoisted_2 = ["poster", "src"];
const _hoisted_3 = ["onClick"];
import { computed } from 'vue';
import store from '@/store';
export default {
  __name: 'PopCover',
  props: {
    modalShow: Boolean,
    video: Object
  },
  emits: ['close'],
  setup(__props, {
    emit
  }) {
    const isMobile = computed(() => store.state.bk.global.isMobile);
    const toggleHandle = () => {
      emit('close');
    };
    return (_ctx, _cache) => {
      return __props.modalShow ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass(['flex', isMobile.value ? 'h5' : 'pc'])
      }, [_createElementVNode("video", {
        class: "video w100 h100",
        poster: __props.video.img_url,
        src: __props.video.link,
        autoplay: "",
        controls: ""
      }, null, 8, _hoisted_2)], 2), _createElementVNode("div", {
        class: "pop",
        onClick: _withModifiers(toggleHandle, ["prevent"])
      }, null, 8, _hoisted_3)])) : _createCommentVNode("", true);
    };
  }
};