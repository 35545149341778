export default {
  namespaced: true,
  state: {
    isMobile: false,
    langs: 'CN',
    isCN: true,
    currentRoute: 0,
  },
  mutations: {
    isMobileChange(state, payload) {
      state.isMobile = payload < 450
    },
    langsChange(state, payload) {
      state.langs = payload
    },
    changeBrowserLang(state, payload) {
      state.isCN = payload
    },
    routeChange(state, payload) {
      state.currentRoute = payload
    },
  },
}
